
import {computed, defineComponent, onBeforeMount, onMounted, onUpdated, ref} from 'vue';
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import Jitsi from "@/services/Jitsi";
import {IActiveVideo} from "@/types/socket";
import {videoConnected} from "@/api/socket/Client";

export default defineComponent({
    name: "FairVideoChat",
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const videoContainer = ref<HTMLElement | null>(null)
        let isConnected = false;
        let scriptLoadingPromise: Promise<unknown> | null = null;

        onBeforeMount(() => {
            scriptLoadingPromise = new Promise(function (resolve, reject) {
                let jitsiScript = document.createElement('script');
                jitsiScript.src = 'https://8x8.vc/external_api.min.js';
                jitsiScript.onload = resolve;
                jitsiScript.onerror = reject;
                document.head.appendChild(jitsiScript);
            });
        })

        const activeVideo = computed<IActiveVideo | null>(() => {
            return store.getters['socket/activeVideoByRoomUrl'](route?.params.roomUrl)
        })

        const socketInitialized = computed(() => {
            return store.getters['socket/isInitialized']
        })

        onUpdated(() => startVideo())

        const startVideo = async () => {
            console.log('TEST: startVideo called');
            if (isConnected) {
                if ((!activeVideo.value && socketInitialized.value)) {
                    // angeblich bin ich connected, das video existiert aber nicht mehr --> wir killen den tab
                    window.close();
                    router.push({
                        name: 'fair',
                        params: {slug: route.params.slug}
                    });
                }
                return;
            }
            await scriptLoadingPromise;
            if (videoContainer.value) {
                // wir schließen den tab auch, falls ich selber shon einen tab mit dem video offen habe
                if ((!activeVideo.value && socketInitialized.value) || (!isConnected && activeVideo.value?.clientConnected)) {
                    window.close();
                    router.push({
                        name: 'fair',
                        params: {slug: route.params.slug}
                    });
                    return;
                }
                if (activeVideo.value) {
                    const jitsi = new Jitsi(videoContainer.value, activeVideo.value.roomId, 'Mein Titel', activeVideo.value.roomId, activeVideo.value.jwt)
                    videoConnected(activeVideo.value.companyId, activeVideo.value.userId);
                    isConnected = true;
                }
            }
        }

        const videoRoomUrl = route?.params.roomUrl ?? null;

        return {
            videoRoomUrl,
            videoContainer,
            socketInitialized,
            activeVideo
        }
    }
});
