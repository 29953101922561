
class Jitsi {
    private roomName: string;
    private api: any;

    constructor(container: HTMLElement, roomName: string, title: string, uuid: string, jwtToken: string) {
        this.roomName = roomName;
        this.api = new (window as any).JitsiMeetExternalAPI('8x8.vc', {
            roomName,
            subject: title,
            height: 700,
            parentNode: container,
            jwt: jwtToken,
            /*userInfo: {
                email: projectUser.email,
                //avatar: projectUser.avatar_url,
                displayName: projectUser.displayName
            },*/
            configOverwrite: {
                brandingRoomAlias: uuid,
                enableUserRolesBasedOnToken: true,
                enableFeaturesBasedOnToken: true,
                prejoinPageEnabled: false,
                disableInviteFunctions: true,
            },
            interfaceConfigOverwrite: {
                DEFAULT_BACKGROUND: '#FFFFFF',
                DEFAULT_REMOTE_DISPLAY_NAME: 'Anonymous',
                SHOW_JITSI_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                HIDE_KICK_BUTTON_FOR_GUESTS: true,

                DISPLAY_WELCOME_PAGE_CONTENT: false,
                GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,

                RECENT_LIST_ENABLED: false,

                HIDE_INVITE_MORE_HEADER: true,
                MOBILE_APP_PROMO: false,

                //DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,

                TOOLBAR_BUTTONS: [
                    'microphone', 'camera', /*'closedcaptions'*/ 'desktop', 'fullscreen',
                    'fodeviceselection', /*'hangup'*/ 'profile', 'chat', /*'recording'*/
                    /*'livestreaming', 'etherpad', 'sharedvideo', 'settings'*/ 'raisehand',
                    'videoquality', 'filmstrip', /*'invite'*/ /*'feedback'*/ /*'stats'*/ 'shortcuts',
                    'tileview', 'videobackgroundblur', /*'download', 'help'*/ 'mute-everyone', 'security'
                ],

                SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile'/*, 'calendar'*/]
            }
        });

        this.api.executeCommand('subject', title);
    }

    setSubject(subject: string) {
        this.api.executeCommand('subject', 'New Conference Subject');
    }

    setVideoQuality() {
        this.api.executeCommand('setVideoQuality', 720);
    }
}


export default Jitsi
